import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./footer.css"
const Footer = () => {
  const {
    site: { info },
  } = useStaticQuery(
    graphql`
      query {
        site {
          info: siteMetadata {
            mail
          }
        }
      }
    `
  )
  return (
    <footer className="footer">
      <div className="footer-wrap">
        <div className="mail">EMAIL: {info.mail} </div>
        <p className="footer-txt">
          Spell Prijevodi &#64;{new Date().getFullYear()} | site by
          <a
            href="https://masu.netlify.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            masu
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
