import React, { useState } from "react"
import Language from "../Language"
import "./header.css"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { Link } from "gatsby"
import HeaderDrop from "../HeaderDrop/HeaderDrop"

const Header = () => {
  const [drop, setDrop] = useState(false)
  return (
    <>
      <nav className="header-section">
        <div className="header-ul">
          <Link to="/" className="header-link">
            <FormattedMessage id="nav.home" />
          </Link>
          <Link to="/translator/" className="header-link">
            <FormattedMessage id="nav.translator" />
          </Link>
          <Link to="/proofreader" className="header-link">
            <FormattedMessage id="nav.proofreader" />
          </Link>
          <Link to="/content-writer" className="header-link">
            <FormattedMessage id="nav.contentwriter" />
          </Link>
          <Link to="/writer" className="header-link">
            <FormattedMessage id="nav.writer" />
          </Link>
          <Link to="/recomendations" className="header-link">
            <FormattedMessage id="nav.recommendations" />
          </Link>
          <Link to="/services-and-discount" className="header-link">
            <FormattedMessage id="nav.services" />
          </Link>
          <Link to="/contact" className="header-link">
            <FormattedMessage id="nav.contact" />
          </Link>
          <Language />
          <button
            tabIndex="0"
            className="header-burger"
            aria-label="Button"
            onClick={() => setDrop(!drop)}
          >
            <div className="b-one"></div>
            <div className="b-two"></div>
            <div className="b-three"></div>
          </button>
        </div>
      </nav>
      <HeaderDrop className={drop ? "drop" : "drop-close"} />
    </>
  )
}

export default Header
