import React from "react"
import "../HeaderDrop/headerdrop.css"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const HeaderDrop = props => {
  return (
    <nav className={props.className}>
      <div>
        <Link to="/">
          <FormattedMessage id="nav.home" />
        </Link>
        <Link to="/translator">
          <FormattedMessage id="nav.translator" />
        </Link>
        <Link to="/proofreader">
          <FormattedMessage id="nav.proofreader" />
        </Link>
        <Link to="/content-writer">
          <FormattedMessage id="nav.contentwriter" />
        </Link>
        <Link to="/writer">
          <FormattedMessage id="nav.writer" />
        </Link>
        <Link to="/recomendations">
          <FormattedMessage id="nav.recommendations" />
        </Link>
        <Link to="/services-and-discount">
          <FormattedMessage id="nav.services" />
        </Link>
        <Link to="/contact">
          <FormattedMessage id="nav.contact" />
        </Link>
      </div>
    </nav>
  )
}

export default HeaderDrop
