import React from "react"
import "./layout.css"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/FooterComponent"

const Layout = ({ children }) => {
  return (
    <div className="container">
      <div className="content">
        <Header />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
