import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"

const languageName = {
  en: "EN",
  hr: "HR",
}

const Language = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <button
              tabindex="0"
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                display: currentLocale === language ? "none" : "block",
                margin: 0,
                color: "#ff00ff",
                cursor: `pointer`,
                border: "none",
                backgroundColor: "transparent",
              }}
            >
              {languageName[language]}
            </button>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language
